export { default as Logo } from '../../components/Logo.vue'
export { default as ProjectsHighlightItem } from '../../components/Projects/HighlightItem.vue'
export { default as ProjectsHighlightsList } from '../../components/Projects/HighlightsList.vue'
export { default as ProjectsPictureList } from '../../components/Projects/PictureList.vue'
export { default as ProjectsTheGallery } from '../../components/Projects/TheGallery.vue'
export { default as TeamsRegionFilter } from '../../components/Teams/RegionFilter.vue'
export { default as TeamsTeamGroup } from '../../components/Teams/TeamGroup.vue'
export { default as TeamsTeamMember } from '../../components/Teams/TeamMember.vue'
export { default as NaviTheHeader } from '../../components/Navi/TheHeader.vue'
export { default as NaviTheLanguage } from '../../components/Navi/TheLanguage.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
